<template>
  <el-dialog
    v-drag
    title="标记"
    :visible.sync="dialogVisible"
    width="460px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="row">
        <div
          v-for="(item, index) in colorList1"
          :key="index"
          class="item"
          :class="{ isActive: isActive === index }"
          :style="{ background: item.background }"
          @click="isActive = index"
        >
          <div class="label" :style="{ background: item.color }"></div>
        </div>
        <div v-if="isActive <= 3 && isActive !== 0" class="panle">
          <span class="item-label">
            <span class="require">*</span> 标签内容
          </span>
          <el-input
            v-model="bgLabel"
            class="input"
            :maxlength="5"
            style="width: 273px; margin-top: 20px; margin-left: 10px"
            placeholder="请输入(限5字)"
          />
        </div>
        <div
          v-for="(item, index) in colorList2"
          :key="`${index}-1`"
          class="item"
          :class="{ isActive: isActive === index + 4 }"
          :style="{ background: item.background }"
          @click="isActive = index + 4"
        >
          <div class="label" :style="{ background: item.color }"></div>
        </div>
        <div v-if="isActive >= 4" class="panle">
          <span class="item-label">
            <span class="require">*</span> 标签内容
          </span>
          <el-input
            v-model="bgLabel"
            class="input"
            :maxlength="5"
            style="width: 273px; margin-top: 20px; margin-left: 10px"
            placeholder="请输入(限5字)"
          />
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { mblHoldRecordSetBgLabel } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      colorList1: [
        {
          color: '#ffffff',
          background: '#ffffff'
        },
        {
          color: 'rgba(254, 69, 61, 1)',
          background: 'rgba(255, 235, 227, 1)'
        },
        {
          color: 'rgba(35, 149, 243, 1)',
          background: 'rgba(242, 249, 255, 1)'
        },
        {
          color: 'rgba(5, 214, 226, 1)',
          background: 'rgba(236, 254, 255, 1)'
        }
      ],
      colorList2: [
        {
          color: 'rgba(53, 186, 131, 1)',
          background: 'rgba(246, 255, 252, 1)'
        },
        {
          color: 'rgba(254, 170, 79, 1)',
          background: 'rgba(252, 247, 235, 1)'
        },
        {
          color: 'rgba(157, 103, 254, 1)',
          background: 'rgba(248, 243, 255, 1)'
        },
        {
          color: 'rgba(255, 116, 68, 1)',
          background: 'rgba(255, 247, 243, 1)'
        }
      ],
      isActive: 0,
      bgLabel: ''
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.isActive = 0
        this.bgLabel = ''
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok() {
      const params = {
        bgLabel: this.bgLabel,
        bgColor: this.isActive > 3 ? this.colorList2[this.isActive - 4].color : this.colorList1[this.isActive].color,
        mblId: this.rowData.mblId
      }
      mblHoldRecordSetBgLabel(params).then((res) => {
        if (res.success) {
          this.$message.success('修改成功')
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 380px;
  height: auto;
  margin: 0 auto;
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      margin-top: 20px;
      width: 80px;
      height: 80px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #eeeeee;
      cursor: pointer;
    }
    .isActive {
      border: 1px solid #2395f3;
      position: relative;
      &::after {
        position: absolute;
        content: " ";
        background: url("../../../assets/images/bill/check.png");
        width: 14px;
        height: 14px;
        left: 50%;
        top: 50%;
        margin-top: -7px;
        margin-left: -7px;
      }
    }
    .label {
      width: 30px;
      height: 15px;
      border-radius: 4px 0px 4px 0px;
      opacity: 1;
    }
  }
  .panle {
    width: 380px;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    margin-top: 13px;
    display: flex;
    .item-label {
      width: 67px;
      height: 80px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 80px;
      margin-left: 10px;
      .require {
        color: rgba(255, 116, 68, 1);
      }
      .input {
        margin-top: 20px;
        margin-left: 10px;
      }
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 40px 40px;
}
/deep/ .el-dialog__header {
  border: 0;
}
</style>
