<template>
  <el-dialog
    v-drag
    title="更改状态"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="content">
      <el-form ref="form" class="form" :model="form">
        <el-form-item prop="status" label="状态">
          <el-select
            v-model="form.status"
            style="width: 100%"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, $index) in checkListStatusOptions"
              :key="$index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="form.status !== 3" class="row">
          <div class="cell">
            <el-form-item label="提单件数">
              <el-input v-model="rowData.mblQty" min="0" disabled />
            </el-form-item>
          </div>
          <div class="cell">
            <el-form-item label="放行件数">
              <el-input v-model="releaseQty" min="0" disabled />
            </el-form-item>
          </div>
          <div class="cell">
            <el-form-item label="扣件件数">
              <el-input
                v-model="form.holdQty"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')" />
            </el-form-item>
          </div>
          <div class="cell">
            <el-form-item label="销毁件数">
              <el-input
                v-model="form.destroyQty"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')" />
            </el-form-item>
          </div>
          <div class="cell">
            <el-form-item label="没收件数">
              <el-input
                v-model="form.confiscateQty"
                min="0"
                oninput="value=value.replace(/[^\d]/g,'')" />
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import config from '@/utils/config'
import { mblHoldRecordSetStatus } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      config,
      form: {
        // releaseQty: '',
        destroyQty: 0,
        holdQty: 0,
        confiscateQty: 0
      },
      loading: false,
      options: []
    }
  },
  computed: {
    checkListStatusOptions() {
        return this.config.checkListStatusOptions.filter(a => a.value !== 10000)
    },
    releaseQty() {
        return this.rowData.mblQty -
         parseInt(this.form.destroyQty, 10) -
         parseFloat(this.form.holdQty, 10) -
         parseFloat(this.form.confiscateQty, 10)
        //   +
        //  +this.rowData.releaseQty
    }
  },
  watch: {
    dialogVisible(val) {
        if (val) {
            this.form = {
                // releaseQty: '',
                destroyQty: this.rowData.destroyQty || 0,
                holdQty: this.rowData.holdQty || 0,
                confiscateQty: this.rowData.confiscateQty || 0
            }
        }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.status === 1 && !(this.form.destroyQty || this.form.holdQty || this.rowData.confiscateQty)) {
            this.$message.error('部分放行必须填写扣件数量')
            return
          }
          this.loading = true
          const params = {
            confiscateQty: this.form.confiscateQty ? parseInt(this.form.confiscateQty, 10) : undefined,
            destroyQty: this.form.destroyQty ? parseInt(this.form.destroyQty, 10) : undefined,
            holdQty: this.form.holdQty ? parseInt(this.form.holdQty, 10) : undefined,
            releaseQty: this.releaseQty ? parseInt(this.releaseQty, 10) : undefined,
            mblId: this.rowData.mblId,
            status: this.form.status
          }
          mblHoldRecordSetStatus(params).then((res) => {
            this.loading = false
            if (res.success) {
              this.$message.success('修改成功')
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 480px;
  height: auto;
  margin: 0 auto;
  .form {
    width: 460px;
    margin-top: 10px;
    &:deep(.el-form-item__label) {
      line-height: 30px;
      font-size: 14px;
    }
    &:deep(.el-form-item) {
      margin-bottom: 10px;
      position: relative;
    }
    overflow: hidden;
  }
  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .cell {
        width: 84px
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 30px 40px;
}
/deep/ .el-dialog__header {
  border: 0;
}
</style>
