var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "标记",
        visible: _vm.dialogVisible,
        width: "460px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _vm._l(_vm.colorList1, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "item",
                  class: { isActive: _vm.isActive === index },
                  style: { background: item.background },
                  on: {
                    click: function ($event) {
                      _vm.isActive = index
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "label",
                    style: { background: item.color },
                  }),
                ]
              )
            }),
            _vm.isActive <= 3 && _vm.isActive !== 0
              ? _c(
                  "div",
                  { staticClass: "panle" },
                  [
                    _c("span", { staticClass: "item-label" }, [
                      _c("span", { staticClass: "require" }, [_vm._v("*")]),
                      _vm._v(" 标签内容 "),
                    ]),
                    _c("el-input", {
                      staticClass: "input",
                      staticStyle: {
                        width: "273px",
                        "margin-top": "20px",
                        "margin-left": "10px",
                      },
                      attrs: { maxlength: 5, placeholder: "请输入(限5字)" },
                      model: {
                        value: _vm.bgLabel,
                        callback: function ($$v) {
                          _vm.bgLabel = $$v
                        },
                        expression: "bgLabel",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.colorList2, function (item, index) {
              return _c(
                "div",
                {
                  key: `${index}-1`,
                  staticClass: "item",
                  class: { isActive: _vm.isActive === index + 4 },
                  style: { background: item.background },
                  on: {
                    click: function ($event) {
                      _vm.isActive = index + 4
                    },
                  },
                },
                [
                  _c("div", {
                    staticClass: "label",
                    style: { background: item.color },
                  }),
                ]
              )
            }),
            _vm.isActive >= 4
              ? _c(
                  "div",
                  { staticClass: "panle" },
                  [
                    _c("span", { staticClass: "item-label" }, [
                      _c("span", { staticClass: "require" }, [_vm._v("*")]),
                      _vm._v(" 标签内容 "),
                    ]),
                    _c("el-input", {
                      staticClass: "input",
                      staticStyle: {
                        width: "273px",
                        "margin-top": "20px",
                        "margin-left": "10px",
                      },
                      attrs: { maxlength: 5, placeholder: "请输入(限5字)" },
                      model: {
                        value: _vm.bgLabel,
                        callback: function ($$v) {
                          _vm.bgLabel = $$v
                        },
                        expression: "bgLabel",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        ),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }