var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "contents" },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "tab" },
          _vm._l(_vm.statusOptions, function (item, $index) {
            return _c(
              "span",
              {
                directives: [
                  {
                    name: "permissson",
                    rawName: "v-permissson",
                    value: `${item.permissson}`,
                    expression: "`${item.permissson}`",
                  },
                ],
                key: $index,
                class: ["tabItem", { active: _vm.activeName === item.value }],
                on: {
                  click: function ($event) {
                    return _vm.handleClick(item.value)
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(
                    item.value === 10000
                      ? `${item.label}(${_vm.tabTotal})`
                      : `${item.label}(${
                          _vm.tabNumer?.find((a) => a.type === +item.value)
                            ?.qty || 0
                        })`
                  )
                ),
              ]
            )
          }),
          0
        ),
        _c("div", { staticClass: "opt" }, [
          _vm.activeName === 0
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  class: { "btn-active": !_vm.notUpdateFlag },
                  staticStyle: { "border-radius": "2px 0px 0px 2px" },
                  on: {
                    click: function ($event) {
                      return _vm.updateChange(false)
                    },
                  },
                },
                [_vm._v("已更新")]
              )
            : _vm._e(),
          _vm.activeName === 0
            ? _c(
                "div",
                {
                  staticClass: "btn",
                  class: { "btn-active": _vm.notUpdateFlag },
                  staticStyle: { "border-radius": "0px 2px 2px 0px" },
                  on: {
                    click: function ($event) {
                      return _vm.updateChange(true)
                    },
                  },
                },
                [_vm._v("未更新")]
              )
            : _vm._e(),
          _vm.filterList.date
            ? _c("div", { staticClass: "checkDate flex_c_c" }, [
                _vm._v(
                  " 查询时间：" +
                    _vm._s(
                      _vm.filterList.date[0] + "~" + _vm.filterList.date[1]
                    ) +
                    " "
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "filter" },
            [
              _c(
                "base-filter",
                {
                  ref: "BaseFilter",
                  attrs: { width: 466, isActive: _vm.isActive },
                  on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
                },
                [
                  _c(
                    "div",
                    { staticClass: "filter" },
                    [
                      _c("SelectFilter", {
                        ref: "selectFilter",
                        attrs: {
                          filterListJson: _vm.filterListJson,
                          filterListId: _vm.filterListId,
                          companyDrapdown: _vm.companyDrapdown,
                        },
                        on: { ok: _vm.ok1 },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "data",
        },
        [
          _vm.height
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: {
                    ..._vm.tableData,
                    fileds: _vm.fileds,
                  },
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  isPager: true,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  dblclick: _vm.dblclick,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "companyName",
                      fn: function (row) {
                        return [
                          _vm.activeName === 10000 || _vm.activeName === 3
                            ? [
                                _vm._v(
                                  " " + _vm._s(row.scope.companyName) + " "
                                ),
                              ]
                            : [
                                _c(
                                  "span",
                                  {
                                    staticClass: "top",
                                    style: {
                                      "background-color": row.scope.bgColor,
                                      "padding-left": row.scope.notUpdateFlag
                                        ? "0px"
                                        : "10px",
                                    },
                                  },
                                  [
                                    row.scope.notUpdateFlag
                                      ? _c("img", {
                                          attrs: {
                                            src: require("@/assets/images/bill/waitUpdate.png"),
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " + _vm._s(row.scope.bgLabel) + " "
                                    ),
                                  ]
                                ),
                                _vm.activeName !== 10000 && _vm.activeName !== 3
                                  ? _c("img", {
                                      staticClass: "editColor",
                                      attrs: {
                                        src: require("@/assets/images/bill/editColor.png"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editColor(row.scope)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " + _vm._s(row.scope.companyName) + " "
                                ),
                              ],
                        ]
                      },
                    },
                    {
                      key: "status",
                      fn: function (row) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "status",
                              class: _vm.status(row.scope.status),
                            },
                            [
                              _vm._v(
                                _vm._s(_vm._f("formatStatus")(row.scope.status))
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "lastEventDescription",
                      fn: function (row) {
                        return [
                          _c("p", { staticClass: "panel" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("更新人"),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(row.scope.lastEventUserName)),
                            ]),
                          ]),
                          _c("p", { staticClass: "panel" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("更新时间"),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(row.scope.lastEventDate)
                                )
                              ),
                            ]),
                          ]),
                          _c("p", { staticClass: "panel" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("内容"),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(row.scope.lastEventDescription)),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "holdInfo",
                      fn: function (row) {
                        return [
                          row.scope.holdInfo.length > 0
                            ? _c("div", { staticClass: "checkInfo" }, [
                                _c("div", [
                                  _c("p", { staticClass: "panel" }, [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("查验日期"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("formatDate")(
                                            row.scope.holdInfo[0].holdDate
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "panel" }, [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("查验类型"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(row.scope.holdInfo[0].holdType)
                                      ),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "panel" }, [
                                    _c("span", { staticClass: "label" }, [
                                      _vm._v("查验原因"),
                                    ]),
                                    _c("span", { staticClass: "value" }, [
                                      _vm._v(
                                        _vm._s(row.scope.holdInfo[0].holdReason)
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "detail" },
                                  [
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "bottom-end",
                                          width: "560",
                                          trigger: "click",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "detail-table" },
                                          [
                                            _c("BaseTable", {
                                              attrs: {
                                                height: 240,
                                                tableData: {
                                                  ..._vm.tableDeatil,
                                                  columns: row.scope.holdInfo,
                                                },
                                                isPager: false,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "holdReason",
                                                    fn: function (detailRow) {
                                                      return [
                                                        _c("el-input", {
                                                          on: {
                                                            blur: function (
                                                              $event
                                                            ) {
                                                              return _vm.blur(
                                                                detailRow,
                                                                row.scope
                                                                  .holdInfo
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value:
                                                              detailRow.scope
                                                                .holdReason,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                detailRow.scope,
                                                                "holdReason",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "detailRow.scope.holdReason",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _c("img", {
                                          attrs: {
                                            slot: "reference",
                                            src: require("@/assets/images/bill/detail.png"),
                                          },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "inspectionSiteName",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "checkInfo" }, [
                            _c("div", [
                              _c("p", { staticClass: "panel" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "label",
                                    staticStyle: { width: "85px" },
                                  },
                                  [_vm._v("查验场")]
                                ),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(row.scope.inspectionSiteName) + " "
                                  ),
                                  _c("img", {
                                    staticClass: "link",
                                    attrs: {
                                      src: require("@/assets/images/bill/link.png"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.link(row.scope)
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                              _c("p", { staticClass: "panel" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "label",
                                    staticStyle: { width: "85px" },
                                  },
                                  [_vm._v("进查验场时间")]
                                ),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        row.scope.inspectionInTime
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _c("p", { staticClass: "panel" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "label",
                                    staticStyle: { width: "85px" },
                                  },
                                  [_vm._v("出查验场时间")]
                                ),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        row.scope.inspectionOutTime
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "detail",
                                on: {
                                  click: function ($event) {
                                    return _vm.updateCheckBoard(row.scope)
                                  },
                                },
                              },
                              [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/editCheck.png"),
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "importerName",
                      fn: function (row) {
                        return [
                          _c("span", [
                            _vm.isOwerCompany(row.scope.importerName)
                              ? _c("span", { staticClass: "importerName" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.isOwerCompany(row.scope.importerName)
                                    )
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" " + _vm._s(row.scope.importerName)),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _vm.activeName === 0
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "editStatus_b",
                                        expression: "'editStatus_b'",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.modifyStatus(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("更改状态")]
                                ),
                                _c("br"),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "billDetail_b",
                                        expression: "'billDetail_b'",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.gotoBill(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("提单详情")]
                                ),
                              ]
                            : _vm._e(),
                          _vm.activeName === 1
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "editStatus_c",
                                        expression: "'editStatus_c'",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.modifyStatus(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("更改状态")]
                                ),
                              ]
                            : _vm._e(),
                          _vm.activeName === 2
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "editStatus_d",
                                        expression: "'editStatus_d'",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.modifyStatus(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("更改状态")]
                                ),
                              ]
                            : _vm._e(),
                          _vm.activeName === 3
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "upload_file",
                                        expression: "'upload_file'",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.uploadFile(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("上传费用凭证")]
                                ),
                                _c("br"),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "billDetail_e",
                                        expression: "'billDetail_e'",
                                      },
                                    ],
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.gotoBill(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("提单详情")]
                                ),
                              ]
                            : _vm._e(),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2847896118
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("UpdateCheckBoardDialog", {
        attrs: { rowData: _vm.rowData, dialogVisible: _vm.dialogVisible },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible = false
          },
        },
      }),
      _c("UpdateCheckBoardStatusDialog", {
        attrs: { rowData: _vm.rowData, dialogVisible: _vm.dialogVisible1 },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible1 = false
          },
        },
      }),
      _c("UpdateCheckBoardColorDialog", {
        attrs: { rowData: _vm.rowData, dialogVisible: _vm.dialogVisible2 },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
        },
      }),
      _c("UploadFeeFileDialog", {
        attrs: { rowData: _vm.rowData, dialogVisible: _vm.dialogVisible3 },
        on: {
          ok: _vm.ok,
          cancel: function ($event) {
            _vm.dialogVisible3 = false
          },
        },
      }),
      _c("follow-up-records-drawer", {
        attrs: {
          drawer: _vm.drawer,
          rowData: {
            ..._vm.rowData,
            id: _vm.rowData.mblId,
            status: 1,
          },
          "bill-type": 2,
        },
        on: {
          close: function ($event) {
            _vm.drawer = false
          },
        },
      }),
      _c("DetailDrawer", {
        attrs: {
          drawer: _vm.drawer2,
          billType: _vm.billType,
          rowData: {
            ..._vm.rowData,
            id: _vm.rowData.mblId,
          },
        },
        on: {
          close: function ($event) {
            _vm.drawer2 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }