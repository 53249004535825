<template>
  <el-dialog
    v-drag
    title="更新查验场信息"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div v-loading="loading" class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-form-item prop="inspectionSiteId" label="查验场">
          <el-select
            v-model="form.inspectionSiteId"
            style="width: 100%"
            placeholder="请选择查验场"
          >
            <el-option
              v-for="(item, $index) in options"
              :key="$index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="inspectionInTime" label="进查验场时间">
          <el-date-picker
            v-model="form.inspectionInTime"
            style="width: 100%"
            align="center"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item prop="inspectionOutTime" label="出查验场时间">
          <el-date-picker
            v-model="form.inspectionOutTime"
            style="width: 100%"
            align="center"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm:ss"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

  <script>
  import { mblHoldRecordSetInspec, inspectionSiteList } from '@/api/bill'
  export default {
    props: {
      dialogVisible: {
        type: Boolean,
        default: false
      },
      rowData: {
        type: Object,
        default: function() {
          return {}
        }
      }
    },
    data() {
      return {
        form: {
            inspectionSiteId: '',
            inspectionInTime: '',
            inspectionOutTime: ''
        },
        rules: {
            inspectionSiteId: [
            { required: true, message: '请选择查验场', trigger: 'blur' }
          ]
          // inspectionInTime: [{ required: true, message: '请选择进查验场时间', trigger: 'blur' }],
          // inspectionOutTime: [{ required: true, message: '请选择出查验场时间', trigger: 'blur' }]
        },
        loading: false,
        options: []
      }
    },
    watch: {
      dialogVisible(val) {
        if (val) {
            if (this.options.length === 0) {
                this.getList()
            }
        }
      }
    },
    methods: {
        async getList() {
            try {
                const res = await inspectionSiteList()
                if (res.success) {
                    this.options = res.data.map(a => {
                      return {
                        value: a.id,
                        label: a.nameCH
                      }
                    })
                } else {
                    this.$message.error(res.errorMessage)
                }
            } catch (error) {
                console.log(error)
            }
        },
      cancel() {
        this.$emit('cancel')
      },
      ok(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
              const params = {
                ...this.form,
                mblId: this.rowData.mblId
              }
              mblHoldRecordSetInspec(params).then((res) => {
                this.loading = false
              if (res.success) {
                this.$message.success('修改成功')
                this.$emit('ok')
              } else {
                this.$message.error(res.errorMessage)
              }
            })
          }
        })
      }
    }
  }
  </script>

  <style lang="less" scoped>
  .content {
    width: 480px;
    height: auto;
    margin: 0 auto;
    .form {
      width: 400px;
      margin-top: 10px;
      &:deep(.el-form-item__label) {
        line-height: 30px;
        font-size: 14px;
      }
      &:deep(.el-form-item) {
        margin-bottom: 10px;
        position: relative;
      }
      overflow: hidden;
    }
    .data {
      width: 100%;
      margin: 10px auto 0;
      height: 240px;
      &:deep(.el-input__inner) {
        border: 0;
      }
      &:deep(.el-table--medium .el-table__cell) {
        padding: 0;
      }
      &:deep(.el-table .cell) {
        padding: 0;
      }
    }
    .fp-cell {
      color: #2395f3;
      cursor: pointer;
      line-height: 40px;
    }
    .icon1 {
      font-size: 20px;
      vertical-align: -5px;
    }
  }
  .footers {
    width: 100%;
    display: flex;
    justify-content: center;
    .cancel {
      width: 120px;
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 16px;
      cursor: pointer;
    }
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
  /deep/ .el-dialog__body {
    padding: 0px 60px 40px;
  }
  /deep/ .el-dialog__header {
    border: 0;
  }
  </style>

