var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "更改状态",
        visible: _vm.dialogVisible,
        width: "520px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "content",
        },
        [
          _c(
            "el-form",
            { ref: "form", staticClass: "form", attrs: { model: _vm.form } },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "status", label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "status", $$v)
                        },
                        expression: "form.status",
                      },
                    },
                    _vm._l(_vm.checkListStatusOptions, function (item, $index) {
                      return _c("el-option", {
                        key: $index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.form.status !== 3
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "提单件数" } },
                          [
                            _c("el-input", {
                              attrs: { min: "0", disabled: "" },
                              model: {
                                value: _vm.rowData.mblQty,
                                callback: function ($$v) {
                                  _vm.$set(_vm.rowData, "mblQty", $$v)
                                },
                                expression: "rowData.mblQty",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "放行件数" } },
                          [
                            _c("el-input", {
                              attrs: { min: "0", disabled: "" },
                              model: {
                                value: _vm.releaseQty,
                                callback: function ($$v) {
                                  _vm.releaseQty = $$v
                                },
                                expression: "releaseQty",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "扣件件数" } },
                          [
                            _c("el-input", {
                              attrs: {
                                min: "0",
                                oninput: "value=value.replace(/[^\\d]/g,'')",
                              },
                              model: {
                                value: _vm.form.holdQty,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "holdQty", $$v)
                                },
                                expression: "form.holdQty",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "销毁件数" } },
                          [
                            _c("el-input", {
                              attrs: {
                                min: "0",
                                oninput: "value=value.replace(/[^\\d]/g,'')",
                              },
                              model: {
                                value: _vm.form.destroyQty,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "destroyQty", $$v)
                                },
                                expression: "form.destroyQty",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "cell" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "没收件数" } },
                          [
                            _c("el-input", {
                              attrs: {
                                min: "0",
                                oninput: "value=value.replace(/[^\\d]/g,'')",
                              },
                              model: {
                                value: _vm.form.confiscateQty,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "confiscateQty", $$v)
                                },
                                expression: "form.confiscateQty",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }