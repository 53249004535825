<template>
  <el-dialog
    v-drag
    title="上传费用凭证"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="receiveAmount" label="费用">
              <el-input
                v-model="receiveAmount"
                placeholder="请输入费用"
                suffix-icon="USD"
              >
                <span
                  slot="suffix">
                  USD
                </span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="source" label="类型">
              <el-select
                v-model="source"
                style="width: 100%"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, $index) in options"
                  :key="$index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <base-upload
        ref="baseUpload"
        style="margin-top: 20px"
        :action="action"
        class="upload"
        @success="success"
        @beforeUpload="beforeUpload"
      ></base-upload>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseUpload = () => import('@/components/base/BaseUpload.vue')
import config from '@/utils/config'
export default {
  components: { BaseUpload },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      options: [
        {
          value: 13,
          label: '查验费'
        },
        {
          value: 14,
          label: '查验服务费'
        }
      ],
      receiveAmount: '',
      source: 13
    }
  },
  computed: {
    action() {
      return `/backEnd/api/${config.supplyCode}/File/uploadMBLFile?source=${this.source}&receiveAmount=${this.receiveAmount}&id=${this.rowData.mblId}`
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.source = 13
        this.receiveAmount = ''
      }
    }
  },
  methods: {
    cancel() {
      this.$refs.baseUpload.init()
      this.$emit('cancel')
    },
    ok() {
      this.$refs.baseUpload.init()
      this.$emit('cancel')
    },
    success(file) {
      if (file) {
        this.$message.success('上传成功')
      }
    },
    beforeUpload() {
      if (!this.receiveAmount) {
        this.$message.error('先填写费用')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 400px;
  height: auto;
  margin: 0 auto;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 60px 40px;
}
/deep/ .el-dialog__header {
  border: 0;
}
</style>
