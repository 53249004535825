<template>
  <div class="contents">
    <div class="header">
      <div class="tab">
        <span
          v-for="(item, $index) in statusOptions"
          :key="$index"
          v-permissson="`${item.permissson}`"
          :class="['tabItem', { active: activeName === item.value }]"
          @click="handleClick(item.value)"
        >{{
          item.value === 10000
            ? `${item.label}(${tabTotal})`
            : `${item.label}(${
                  tabNumer?.find((a) => a.type === +item.value)?.qty || 0
            })`
        }}</span
        >
      </div>
      <div class="opt">
        <div v-if="activeName === 0" class="btn" style="border-radius: 2px 0px 0px 2px;" :class="{'btn-active': !notUpdateFlag}" @click="updateChange(false)">已更新</div>
        <div v-if="activeName === 0" class="btn" style="border-radius: 0px 2px 2px 0px;" :class="{'btn-active': notUpdateFlag}" @click="updateChange(true)">未更新</div>
        <div v-if="filterList.date" class="checkDate flex_c_c">
          查询时间：{{ filterList.date[0] + "~" + filterList.date[1] }}
        </div>
        <div class="filter">
          <base-filter
            ref="BaseFilter"
            :width="466"
            :isActive="isActive"
            @sendFilter="sendFilter"
            @reset="reset"
          >
            <div class="filter">
              <SelectFilter
                ref="selectFilter"
                :filterListJson="filterListJson"
                :filterListId="filterListId"
                :companyDrapdown="companyDrapdown"
                @ok="ok1"
              ></SelectFilter>
            </div>
          </base-filter>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="data">
      <BaseTable
        v-if="height"
        :height="height"
        :tableData="{
          ...tableData,
          fileds,
        }"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :isPager="true"
        @handlePageChange="handlePageChange"
        @dblclick="dblclick"
      >
        <template slot="companyName" slot-scope="row">
          <template v-if="activeName === 10000 || activeName === 3">
            {{ row.scope.companyName }}
          </template>
          <template v-else>
            <span
              class="top"
              :style="{
                'background-color': row.scope.bgColor,
                'padding-left': row.scope.notUpdateFlag ? '0px' : '10px',
              }"
            >
              <img
                v-if="row.scope.notUpdateFlag"
                src="@/assets/images/bill/waitUpdate.png"
              />
              {{ row.scope.bgLabel }}
            </span>
            <img
              v-if="activeName !== 10000 && activeName !== 3"
              class="editColor"
              src="@/assets/images/bill/editColor.png"
              @click="editColor(row.scope)"
            />
            {{ row.scope.companyName }}
          </template>
        </template>
        <template slot="status" slot-scope="row">
          <span class="status" :class="status(row.scope.status)">{{
            row.scope.status | formatStatus
          }}</span>
        </template>
        <template slot="lastEventDescription" slot-scope="row">
          <p class="panel">
            <span class="label">更新人</span>
            <span class="value">{{ row.scope.lastEventUserName }}</span>
          </p>
          <p class="panel">
            <span class="label">更新时间</span>
            <span class="value">{{
              row.scope.lastEventDate | formatDate
            }}</span>
          </p>
          <p class="panel">
            <span class="label">内容</span>
            <span class="value">{{ row.scope.lastEventDescription }}</span>
          </p>
        </template>
        <template slot="holdInfo" slot-scope="row">
          <div v-if="row.scope.holdInfo.length > 0" class="checkInfo">
            <div>
              <p class="panel">
                <span class="label">查验日期</span>
                <span class="value">{{
                  row.scope.holdInfo[0].holdDate | formatDate
                }}</span>
              </p>
              <p class="panel">
                <span class="label">查验类型</span>
                <span class="value">{{ row.scope.holdInfo[0].holdType }}</span>
              </p>
              <p class="panel">
                <span class="label">查验原因</span>
                <span class="value">{{
                  row.scope.holdInfo[0].holdReason
                }}</span>
              </p>
            </div>
            <div class="detail">
              <el-popover placement="bottom-end" width="560" trigger="click">
                <div class="detail-table">
                  <BaseTable
                    :height="240"
                    :tableData="{
                      ...tableDeatil,
                      columns: row.scope.holdInfo,
                    }"
                    :isPager="false"
                  >
                    <template slot="holdReason" slot-scope="detailRow">
                      <el-input v-model="detailRow.scope.holdReason" @blur="blur(detailRow, row.scope.holdInfo)" />
                    </template>
                  </BaseTable>
                </div>
                <img slot="reference" src="@/assets/images/bill/detail.png" />
              </el-popover>
            </div>
          </div>
        </template>
        <template slot="inspectionSiteName" slot-scope="row">
          <div class="checkInfo">
            <div>
              <p class="panel">
                <span class="label" style="width: 85px">查验场</span>
                <span
                  class="value"
                >{{ row.scope.inspectionSiteName }}
                  <img
                    class="link"
                    src="@/assets/images/bill/link.png"
                    @click="link(row.scope)"
                  /></span>
              </p>
              <p class="panel">
                <span class="label" style="width: 85px">进查验场时间</span>
                <span class="value">{{
                  row.scope.inspectionInTime | formatDate
                }}</span>
              </p>
              <p class="panel">
                <span class="label" style="width: 85px">出查验场时间</span>
                <span class="value">{{
                  row.scope.inspectionOutTime | formatDate
                }}</span>
              </p>
            </div>
            <div class="detail" @click="updateCheckBoard(row.scope)">
              <img src="@/assets/images/bill/editCheck.png" />
            </div>
          </div>
        </template>
        <template slot="importerName" slot-scope="row">
          <span
          ><span
             v-if="isOwerCompany(row.scope.importerName)"
             class="importerName"
           >{{ isOwerCompany(row.scope.importerName) }}</span
           >
            {{ row.scope.importerName }}</span
          >
        </template>
        <template slot="opt" slot-scope="row">
          <template v-if="activeName === 0">
            <el-button
              v-permissson="'editStatus_b'"
              type="text"
              @click="modifyStatus(row.scope)"
            >更改状态</el-button
            >
            <br />
            <el-button
              v-permissson="'billDetail_b'"
              type="text"
              @click="gotoBill(row.scope)"
            >提单详情</el-button
            >
          </template>
          <template v-if="activeName === 1">
            <el-button
              v-permissson="'editStatus_c'"
              type="text"
              @click="modifyStatus(row.scope)"
            >更改状态</el-button
            >
          </template>
          <template v-if="activeName === 2">
            <el-button
              v-permissson="'editStatus_d'"
              type="text"
              @click="modifyStatus(row.scope)"
            >更改状态</el-button
            >
          </template>
          <template v-if="activeName === 3">
            <el-button
              v-permissson="'upload_file'"
              type="text"
              @click="uploadFile(row.scope)"
            >上传费用凭证</el-button
            >
            <br />
            <el-button
              v-permissson="'billDetail_e'"
              type="text"
              @click="gotoBill(row.scope)"
            >提单详情</el-button
            >
          </template>
        </template>
      </BaseTable>
    </div>
    <UpdateCheckBoardDialog
      :rowData="rowData"
      :dialogVisible="dialogVisible"
      @ok="ok"
      @cancel="dialogVisible = false"
    ></UpdateCheckBoardDialog>
    <UpdateCheckBoardStatusDialog
      :rowData="rowData"
      :dialogVisible="dialogVisible1"
      @ok="ok"
      @cancel="dialogVisible1 = false"
    ></UpdateCheckBoardStatusDialog>
    <UpdateCheckBoardColorDialog
      :rowData="rowData"
      :dialogVisible="dialogVisible2"
      @ok="ok"
      @cancel="dialogVisible2 = false"
    ></UpdateCheckBoardColorDialog>
    <UploadFeeFileDialog
      :rowData="rowData"
      :dialogVisible="dialogVisible3"
      @ok="ok"
      @cancel="dialogVisible3 = false"
    ></UploadFeeFileDialog>
    <follow-up-records-drawer
      :drawer="drawer"
      :rowData="{
        ...rowData,
        id: rowData.mblId,
        status: 1
      }"
      :bill-type="2"
      @close="drawer = false"
    ></follow-up-records-drawer>
    <DetailDrawer
      :drawer="drawer2"
      :billType="billType"
      :rowData="{
        ...rowData,
        id: rowData.mblId
      }"
      @close="drawer2 = false"
    ></DetailDrawer>
  </div>
</template>

<script>
import { permissions } from '@/utils/common'
const BaseFilter = () => import('@/components/filter/BaseFilter')
const BaseTable = () => import('@/components/table/BaseTable')
const SelectFilter = () => import('@/components/filter/SelectFilter')
import UploadFeeFileDialog from './components/UploadFeeFileDialog'
import UpdateCheckBoardColorDialog from './components/UpdateCheckBoardColorDialog'
import UpdateCheckBoardStatusDialog from './components/UpdateCheckBoardStatusDialog'
import UpdateCheckBoardDialog from './components/UpdateCheckBoardDialog'
import DetailDrawer from './components/DetailDrawer.vue'
const FollowUpRecordsDrawer = () => import('@/components/drawer/FollowUpRecordsDrawer')
import { mblHoldRecordPages, mblHoldRecordSetHoldInfo } from '@/api/bill'
import { getSysTableUser } from '@/api/system'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
import { myMixins } from '@/mixins'
// import { getRouteByCode } from '@/utils/common'
export default {
  name: 'CheckList',
  components: {
    BaseFilter,
    BaseTable,
    SelectFilter,
    UpdateCheckBoardDialog,
    UpdateCheckBoardStatusDialog,
    UpdateCheckBoardColorDialog,
    UploadFeeFileDialog,
    FollowUpRecordsDrawer,
    DetailDrawer
  },
  filters: {
    formatStatus: function(value) {
      if (value || value === 0) {
        const item = config.checkListStatusOptions.find(
          (a) => parseInt(a.value, 10) === value
        )
        return item.label
      } else {
        return ''
      }
    },
    formatDate(date) {
      return date ? date.slice(0, date.length - 3) : ''
    }
  },
  mixins: [myMixins],
  data() {
    return {
      config,
      height: 0,
      activeName: 10000,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      loading: false,
      filterList: {},
      filterListJson: [],
      tableData: {
        fileds: [],
        columns: [],
        total: 0
      },
      tableDeatil: {
        fileds: [
          {
            filed: 'holdDate',
            label: '查验日期',
            width: '150',
            formatter: (row) =>
              row.holdDate
                ? row.holdDate.slice(0, row.holdDate.length - 3)
                : ''
          },
          {
            filed: 'holdType',
            label: '查验类型',
            width: '100px'
          },
          {
            filed: 'holdReason',
            label: '查验原因(双击修改)',
            width: '',
            isCustom: true
          },
          {
            filed: 'holdCode',
            label: '查验代码',
            width: '100px'
          }
        ],
        columns: [],
        total: 0
      },
      isActive: false,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      drawer: false,
      drawer2: false,
      billType: 0,
      userOptions: [],
      tabNumer: [],
      tabTotal: 0,
      useLogId: '0',
      statusOptions: config.checkListStatusOptions,
      editData: {},
      tableResData: {
        columns: [],
        total: 0
      },
      filterListId: '',
      rowData: {},
      notUpdateFlag: true
    }
  },
  computed: {
    ...mapGetters([
      'addressDropOption',
      'companyDrapdown',
      'addressBookOptions'
    ]),
    addressDropCodeOption() {
      return (
        this.addressDropOption &&
        this.addressDropOption.map((item) => {
          return {
            value: item.code,
            label: item.label
          }
        })
      )
    },
    printPermissson() {
      return function(v) {
        const perNumber = [1, 2, 3]
        if (perNumber.includes(v)) {
          return true
        } else {
          return false
        }
      }
    },
    fileds() {
      const arr = [
        {
          filed: 'companyName',
          label: '客户',
          width: '180px',
          fixed: 'left',
          isCustom: true
        },
        {
          filed: 'blNo',
          label: '主提单号',
          width: '200',
          isCopy: true,
          fixed: 'left',
          align: 'left'
        },
        {
          filed: 'containerNo',
          label: '柜号',
          width: '200',
          fixed: 'left',
          isCopy: true,
          align: 'left'
        },
        {
          filed: 'status',
          label: '状态',
          width: '120px',
          isCustom: true
        },
        {
          filed: 'lastEventDescription',
          label: '最新跟进记录(双击备注)',
          width: '240px',
          isCustom: true
        },
        {
          filed: 'holdInfo',
          label: '查验信息',
          width: '240px',
          isCustom: true
        },
        {
          filed: 'inspectionSiteName',
          label: '查验场信息',
          width: '280px',
          isCustom: true
        },
        {
          filed: 'eta',
          label: 'ETA',
          width: '150',
          formatter: (row) =>
            row.eta ? row.eta.slice(0, row.eta.length - 3) : ''
        },
        {
          filed: 'importerName',
          label: '进口商',
          width: '200px',
          isCustom: true
        },
        {
          filed: 'clearenceSupplier',
          label: '清关行',
          width: '200px'
        },
        {
          filed: 'createdOn',
          label: '创建日期',
          width: '150',
          formatter: (row) =>
            row.createdOn
              ? row.createdOn.slice(0, row.createdOn.length - 3)
              : ''
        },
        {
          filed: 'forecastAcceptUserName',
          label: '预报处理人',
          width: '100px'
          // formatter: (row) => {
          //   return row.isISA ? '是' : '否'
          // }
        },
        {
          filed: 'originHarborName',
          label: '始发港/机场',
          width: '150px'
        },
        {
          filed: 'destinationHarborName',
          label: '目的港/机场',
          width: '150px'
        },
        {
          filed: 'ata',
          label: 'ATA',
          width: '150',
          formatter: (row) =>
            row.ata ? row.ata.slice(0, row.ata.length - 3) : ''
          // formatter: (row) => {
          //   return `${row.weight ? row.weight : 0} KG`
          // }
        },
        {
          filed: 'atd',
          label: 'ATD',
          width: '150',
          formatter: (row) =>
            row.atd ? row.atd.slice(0, row.atd.length - 3) : ''
        }
      ]
      if (this.activeName !== 10000) {
        arr.push({
          filed: 'opt',
          label: '操作',
          width: '120px',
          isCustom: true,
          fixed: 'right'
        })
      }
      return arr
    }
  },
  created() {
    if (!this.companyDrapdown.length) {
      this.$store.dispatch('getCompanyDrapdown')
    }
    // this.filterList.date = getRecentMonthDate(1)
    this.getList(this.pager)
    this.getFilter()
  },
  async mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
    if (!this.addressBookOptions || this.addressBookOptions.length === 0) {
      this.$store.dispatch('getAddressBookOptions', { type: -1 })
    }
    if (!this.$store.state.connect.connect.tabCode) {
      const menuList = this.$store.getters.initRouter
      const menu = permissions(this.$route.meta.code, menuList)
      const codeLits = [
        [(name) => name === 'QB', () => 10000],
        [(name) => name === 'CYZ', () => 0],
        [(name) => name === 'BFFX', () => 1],
        [(name) => name === 'QBFX', () => 2],
        [(name) => name === 'WJ', () => 3]
      ]
      this.activeName = codeLits.find((a) => a[0](menu.children[0].code))[1]()
    }
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    updateChange(flag) {
      this.notUpdateFlag = flag
      const param = {
        pageIndex: 1,
        pageSize: this.pager.pageSize
      }
      this.pager = param
      this.selectionItem = []
      this.getList(param)
    },
    status(status) {
      const statusObj = {
        0: 'status-0',
        1: 'status-1',
        2: 'status-2',
        3: 'status-3'
      }
      return statusObj[status]
    },
    isOwerCompany(company) {
      const item = this.addressBookOptions.find(
        (a) => a.companyName === company
      )
      return item?.supplierId ? '自有' : ''
    },
    handleClick(index) {
      this.activeName = index
      const param = {
        pageIndex: 1,
        pageSize: this.pager.pageSize
      }
      this.pager = param
      this.selectionItem = []
      this.getList(param)
    },
    dblclick(row) {
      this.rowData = row
      this.drawer = true
    },
    async getFilter() {
      try {
        const res = await getSysTableUser({
          tableCode: 'MBLHoldRecord',
          type: 1
        })
        if (res.success) {
          this.filterListJson = JSON.parse(res.data.value)
          this.filterListId = res.data.id
          console.log('filterListJson', this.filterListJson)
        }
      } catch (error) {
        console.warn(error)
      }
    },
    ok1() {
      this.getFilter()
    },
    handlePageChange(pager) {
      this.pager = pager
      this.getList(pager)
    },
    sendFilter() {
      const filterList = this.$refs.selectFilter.getData()
      if (Object.keys(filterList).length > 0) {
        this.isActive = true
      }
      this.filterList = filterList
      this.initList()
    },
    async getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            status: this.activeName !== 10000 ? this.activeName : undefined,
            ...this.sortable,
            ...this.filterList,
            notUpdateFlag: this.activeName === 0 ? this.notUpdateFlag : undefined
            // ...this.formatFilter()
          }
        }
        const res = await mblHoldRecordPages(params)
        this.loading = false
        if (res.success) {
          this.tableData.columns = res.data.list
          this.tableData.total = res.data.total
          this.tabNumer = res.data.tabNumer
          this.tabTotal = res.data.tabTotal
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    reset() {
      this.$refs.selectFilter.reset()
      this.isActive = false
    },
    ok() {
      this.dialogVisible = false
      this.dialogVisible1 = false
      this.dialogVisible2 = false
      this.initList()
    },
    updateCheckBoard(row) {
      this.rowData = row
      this.dialogVisible = true
    },
    modifyStatus(row) {
      this.rowData = row
      this.dialogVisible1 = true
    },
    editColor(row) {
      this.rowData = row
      this.dialogVisible2 = true
    },
    uploadFile(row) {
      this.rowData = row
      this.dialogVisible3 = true
    },
    gotoBill(row) {
      this.rowData = row
      this.drawer2 = true
      this.billType = row.containerNo ? 1 : 0
      // const menuCode = row.containerNo ? 'HYTD' : 'KYTD'
      // const path = getRouteByCode(menuCode)
      // const params = { menuCode, queryJson: JSON.stringify({ 'mblNo': row.blNo, queryParam: { 'mblNo': [row.blNo], status: '', subStatus: '' }}) }
      // this.$store.commit('SET_CONNNET', params)
      // if (path !== this.$route.fullPath) {
      //   this.$router.push(path)
      // }
    },
    initList() {
      const param = {
        pageIndex: 1,
        pageSize: 20
      }
      this.pager = param
      this.getList(param)
    },
    async blur(row, holdInfo) {
      console.log(row, holdInfo)
      try {
        const res = await mblHoldRecordSetHoldInfo(holdInfo)
        if (res.success) {
          this.$message.success('更新成功')
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    link(row) {
      window.open(row.inspectionSiteUrl, '_blank')
    }
  }
}
</script>

<style lang="less" scoped>
.contents {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  position: relative;
  .header {
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tabItem {
    padding: 20px;
    width: 42px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    cursor: pointer;
  }
  .active {
    color: #2395f3;
  }
  .btn {
    width: 60px;
    height: 30px;
    background: #2395F3;
    background: #EEEEEE;
    opacity: 1;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 30px;
    cursor: pointer;
  }
  .btn-active {
    color: #FFFFFF;
    background: #2395F3;
  }
  .data {
    width: 100%;
    height: calc(100% - 64px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
  }
  .top {
    position: absolute;
    padding: 0 10px;
    display: inline-block;
    top: 0;
    left: 0;
    height: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
  }
  .editColor {
    position: absolute;
    left: 5px;
    cursor: pointer;
  }
  .status {
    width: 60px;
    display: inline-block;
    height: 26px;
    border-radius: 4px 4px 4px 4px;
    font-size: 12px;
    font-weight: 400;
    color: #35ba83;
    line-height: 26px;
  }
  .status-0 {
    background: rgba(35, 149, 243, 0.1);
    color: #2395f3;
  }
  .status-1 {
    background: rgba(255, 116, 68, 0.1);
    color: rgba(255, 116, 68, 1);
  }
  .status-2 {
    background: rgba(53, 186, 131, 0.1);
    color: rgba(53, 186, 131, 1);
  }
  .status-3 {
    background: rgba(157, 103, 254, 0.1);
    color: rgba(157, 103, 254, 1);
  }
  .panel {
    width: 100%;
    text-align: left;
    .label {
      width: 60px;
      height: 20px;
      background: rgba(255, 116, 68, 0.1);
      border-radius: 2px 2px 2px 2px;
      font-size: 12px;
      font-weight: 400;
      color: #ff7444;
      line-height: 20px;
      text-align: center;
      display: inline-block;
    }
    .value {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-left: 5px;
      max-width: 126px;
      display: inline-block;
    }
    .link {
      vertical-align: -3px;
      cursor: pointer;
    }
  }
  .checkInfo {
    display: flex;
    .detail {
      margin-left: 20px;
      display: flex;
      align-items: center;
      img {
        cursor: pointer;
      }
    }
  }
  .importerName {
    display: inline-block;
    width: 30px;
    height: 20px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    text-align: center;
  }
  .opt {
    display: flex;
    & div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      cursor: pointer;
      box-sizing: border-box;
    }
    .filter {
      height: 30px;
      margin-left: 8px;
    }
  }
}
.detail-table {
  width: 520px;
  height: 240px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  margin: 8px auto;
  &:deep(.el-input__inner) {
    border: 0;
  }
}
</style>
